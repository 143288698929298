<template>
  <section class="main-section lnb-tree-section">
    <main-section-header
      title="AR 컨텐츠 관리"
      :is-sticky="true"
      :is-smaller="true"
      :selected-item-name="selectedEqpNameCode"
    >
      <button-basic
        v-show="selectedProjectId && !isForbiddenError"
        slot="button"
        color="primary"
        size="s"
        @click="togglePopup('TaskPopup')"
      >
        <plus-icon :size="16" />
        등록
      </button-basic>
    </main-section-header>
    <task-list
      v-if="selectedProjectId"
      :selected-eqp-id="selectedProjectId"
      :selected-eqp-by-group="selectedEqpByGroup"
    />
    <div v-else class="no-data-description">
      <loading-bar v-if="hasEqpByGroup === null" />
      <none-eqp-desc item-name="타깃이미지" v-else-if="hasEqpByGroup === false" />
      <p v-else-if="hasEqpByGroup === true">좌측에서 설비를 선택하면, 등록된 타깃이미지를 확인할 수 있습니다.</p>
    </div>
    <transition name="modal">
      <task-popup v-if="showedPopupName === 'TaskPopup'" :project-id="selectedProjectId" @close="togglePopup" />
    </transition>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import TaskList from './component/taskList/TaskList';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import TaskPopup from '@/component/popup/taskPopup/TaskPopup';
import LoadingBar from '@/component/loadingBar/loadingBar';
import NoneEqpDesc from '@/component/noneEqpDesc/NoneEqpDesc';
import eqpNameCodeByGroup from '@/asset/js/eqpNameCodeByGroup';

export default {
  name: 'ArContentsList',
  props: [],
  data() {
    return {
      projectTasks: null,
      showedPopupName: '',
      selectedProjectId: this.$route.query.project,
      isForbiddenError: true,
    };
  },
  computed: {
    ...mapState('users', ['myInfo']),
    ...mapState('factoryEquipments', ['selectedEqpByGroup', 'hasEqpByGroup']),
    selectedEqpNameCode() {
      return eqpNameCodeByGroup(this);
    },
  },
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {
    $route() {
      this.selectedProjectId = this.$route.query.project;
    },
    selectedProjectId(newVal, oldVal) {
      if (Number(newVal) !== Number(oldVal)) {
        this.initRenderData();
      }
    },
  },
  methods: {
    ...mapMutations('factoryEquipments', ['SET_SELECTED_EQP_BY_GROUP']),
    ...mapActions('manualTasks', ['GET_PROJECT_TASKS']),
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    initRenderData() {
      if (this.selectedProjectId) {
        this.getProjectTasks();
      } else {
        this.SET_SELECTED_EQP_BY_GROUP({});
      }
    },
    async getProjectTasks() {
      try {
        await this.GET_PROJECT_TASKS(this.selectedProjectId);
        this.isForbiddenError = false;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        } else if (error.response.status === 403) {
          this.isForbiddenError = true;
        }
      }
    },
  },
  components: {
    NoneEqpDesc,
    LoadingBar,
    TaskPopup,
    MainSectionHeader,
    TaskList,
  },
};
</script>

<style scoped lang="scss">
@import 'ArContentsList';
</style>
