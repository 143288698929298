var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section lnb-tree-section" },
    [
      _c(
        "main-section-header",
        {
          attrs: {
            title: "AR 컨텐츠 관리",
            "is-sticky": true,
            "is-smaller": true,
            "selected-item-name": _vm.selectedEqpNameCode,
          },
        },
        [
          _c(
            "button-basic",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedProjectId && !_vm.isForbiddenError,
                  expression: "selectedProjectId && !isForbiddenError",
                },
              ],
              attrs: { slot: "button", color: "primary", size: "s" },
              on: {
                click: function ($event) {
                  return _vm.togglePopup("TaskPopup")
                },
              },
              slot: "button",
            },
            [_c("plus-icon", { attrs: { size: 16 } }), _vm._v(" 등록 ")],
            1
          ),
        ],
        1
      ),
      _vm.selectedProjectId
        ? _c("task-list", {
            attrs: {
              "selected-eqp-id": _vm.selectedProjectId,
              "selected-eqp-by-group": _vm.selectedEqpByGroup,
            },
          })
        : _c(
            "div",
            { staticClass: "no-data-description" },
            [
              _vm.hasEqpByGroup === null
                ? _c("loading-bar")
                : _vm.hasEqpByGroup === false
                ? _c("none-eqp-desc", { attrs: { "item-name": "타깃이미지" } })
                : _vm.hasEqpByGroup === true
                ? _c("p", [
                    _vm._v(
                      "좌측에서 설비를 선택하면, 등록된 타깃이미지를 확인할 수 있습니다."
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
      _c(
        "transition",
        { attrs: { name: "modal" } },
        [
          _vm.showedPopupName === "TaskPopup"
            ? _c("task-popup", {
                attrs: { "project-id": _vm.selectedProjectId },
                on: { close: _vm.togglePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }