var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tasks-wrapper basic-scroll",
      attrs: { id: "tasks-wrapper" },
    },
    [
      _c(
        "div",
        { staticClass: "tasks-header" },
        [
          _c("drop-down-box", {
            class: {
              "visibility-hidden": !_vm.tasks || _vm.tasks.length === 0,
            },
            attrs: {
              id: "filter-task-status",
              value: _vm.filteredTaskStatus,
              optionItemsData: Object.keys(_vm.taskFilteringOptionNames),
              optionNames: _vm.taskFilteringOptionNames,
            },
            on: { selectedOption: _vm.onSelectedOption },
          }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.tasks && _vm.tasks.length !== 0
          ? _c(
              "ul",
              _vm._l(_vm.filteredTask, function (task, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    attrs: { id: task.id },
                    on: { mouseleave: _vm.onMouseLeaveTask },
                  },
                  [
                    _c("div", { staticClass: "task-image-section" }, [
                      _c(
                        "div",
                        {
                          staticClass: "task-image background-image",
                          style: {
                            backgroundImage:
                              "url(" +
                              _vm.getTaskContentType(task).manualTarget[
                                "rscURL"
                              ] +
                              ")",
                          },
                          on: { click: _vm.onClickTask },
                        },
                        [
                          _c(
                            "button-basic",
                            {
                              staticClass: "edit-button hover-show",
                              attrs: { size: "s", width: 100, height: 32 },
                              on: {
                                click: function ($event) {
                                  return _vm.onMoveScenePage(task)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("message.task_edit_btn")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "more-button more hover-show",
                              on: {
                                click: function ($event) {
                                  return _vm.onClickMore(task)
                                },
                              },
                            },
                            [
                              _c("vue-material-icon", {
                                attrs: { name: "more_horiz", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "more-list more",
                          attrs: { id: "task-more-list-" + task.id },
                        },
                        [
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.togglePopup("TaskPopup")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("message.task_more_1_lbl")))]
                          ),
                          [
                            _c("li", { on: { click: _vm.onClickCopyTask } }, [
                              _vm._v(_vm._s(_vm.$t("message.task_more_7_lbl"))),
                            ]),
                            _c(
                              "li",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.togglePopup("CopyPopup")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("message.task_more_8_lbl"))
                                ),
                              ]
                            ),
                            task["publishContent"] && task["saveContent"]
                              ? _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.togglePopup(
                                          "ConfirmPopup",
                                          "TARGET_UPDATE",
                                          _vm.getTaskContentType(task).name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("message.task_more_5_lbl")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : task["saveContent"]
                              ? _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.togglePopup(
                                          "ConfirmPopup",
                                          "TARGET_PUBLISH",
                                          _vm.getTaskContentType(task).name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("message.task_more_2_lbl")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            task["publishContent"]
                              ? _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.togglePopup(
                                          "ConfirmPopup",
                                          "TARGET_UNPUBLISH",
                                          _vm.getTaskContentType(task).name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("message.task_more_4_lbl")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "li",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.togglePopup(
                                      "ConfirmPopup",
                                      "TARGET_DELETE",
                                      _vm.getTaskContentType(task).name
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("message.task_more_3_lbl")) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "task-info-section",
                        on: { click: _vm.onClickTask },
                      },
                      [
                        _c(
                          "h4",
                          {
                            attrs: { title: _vm.getTaskContentType(task).name },
                          },
                          [_vm._v(_vm._s(_vm.getTaskContentType(task).name))]
                        ),
                        _c("p", [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("message.task_last_edit_lbl")) +
                                " "
                            ),
                            _c("span", { staticClass: "update-date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("localDateYYYYMMDDTime")(task.updateDT)
                                )
                              ),
                            ]),
                          ]),
                          task["publishContent"]
                            ? _c(
                                "span",
                                { staticClass: "publish-status published" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("message.task_publish_lbl")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : !task["publishContent"] && task["saveContent"]
                            ? _c(
                                "span",
                                { staticClass: "publish-status unpublished" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("message.task_unpublish_lbl")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          task["publishContent"] && task["saveContent"]
                            ? _c(
                                "span",
                                { staticClass: "publish-status published" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("message.task_update_lbl")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      !_vm.tasks || _vm.tasks.length === 0
        ? _c(
            "div",
            { staticClass: "center-page list-data-none" },
            [
              _vm.tasks === null
                ? _c("loading-bar")
                : _vm.tasks.length === 0
                ? _c("p", [
                    _c("b", [_vm._v("등록된 타깃이미지가 없습니다.")]),
                    _c("span", [
                      _vm._v("등록버튼을 클릭하여 타깃이미지를 등록 하세요."),
                    ]),
                  ])
                : _vm.filteredTask.length === 0
                ? _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("message.no_search_result")) + " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.content
        ? _c("div", { staticClass: "toast-popup toast-popup-middle" }, [
            _vm._v(" " + _vm._s(_vm.toastTitle) + " "),
          ])
        : _vm._e(),
      _c("div", { staticClass: "toast-popup toast-popup-bottom" }, [
        _c("img", {
          attrs: {
            src: "/images/atoms-input-state-success-icon.svg",
            alt: "Success Icon",
          },
        }),
        _vm._v(" " + _vm._s(_vm.toastTitle) + " "),
      ]),
      _c(
        "transition",
        { attrs: { name: "modal" } },
        [
          _vm.showedPopupName === "TaskPopup"
            ? _c("task-popup", {
                attrs: {
                  "selected-eqp-id": _vm.projectId,
                  "selected-task": _vm.selectedTask,
                },
                on: { close: _vm.togglePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
          "i18n-named-format": _vm.i18nNamedFormat,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmInPopup },
      }),
      _c("copy-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "CopyPopup",
          title: "target_move_title",
          description: "target_move_description",
          button: "target_move_button",
          "eqp-groups": [_vm.selectedEqpByGroup.eqpGroup],
        },
        on: { close: _vm.togglePopup, confirm: _vm.putMoveTask },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }