<template>
  <div class="tasks-wrapper basic-scroll" id="tasks-wrapper">
    <div class="tasks-header">
      <drop-down-box
        :class="{ 'visibility-hidden': !tasks || tasks.length === 0 }"
        id="filter-task-status"
        :value="filteredTaskStatus"
        :optionItemsData="Object.keys(taskFilteringOptionNames)"
        :optionNames="taskFilteringOptionNames"
        @selectedOption="onSelectedOption"
      />
    </div>
    <transition name="slide-fade">
      <ul v-if="tasks && tasks.length !== 0">
        <li :key="index" v-for="(task, index) in filteredTask" :id="task.id" @mouseleave="onMouseLeaveTask">
          <div class="task-image-section">
            <div
              class="task-image background-image"
              :style="{ backgroundImage: `url(${getTaskContentType(task).manualTarget['rscURL']})` }"
              @click="onClickTask"
            >
              <button-basic
                class="edit-button hover-show"
                size="s"
                :width="100"
                :height="32"
                @click="onMoveScenePage(task)"
              >
                {{ $t('message.task_edit_btn') }}
              </button-basic>
              <button class="more-button more hover-show" @click="onClickMore(task)">
                <vue-material-icon name="more_horiz" :size="16" />
              </button>
            </div>
            <ul class="more-list more" :id="'task-more-list-' + task.id">
              <li @click="togglePopup('TaskPopup')">{{ $t('message.task_more_1_lbl') }}</li>
              <template>
                <li @click="onClickCopyTask">{{ $t('message.task_more_7_lbl') }}</li>
                <li @click="togglePopup('CopyPopup')">{{ $t('message.task_more_8_lbl') }}</li>
                <li
                  v-if="task['publishContent'] && task['saveContent']"
                  @click="togglePopup('ConfirmPopup', 'TARGET_UPDATE', getTaskContentType(task).name)"
                >
                  {{ $t('message.task_more_5_lbl') }}
                </li>
                <li
                  v-else-if="task['saveContent']"
                  @click="togglePopup('ConfirmPopup', 'TARGET_PUBLISH', getTaskContentType(task).name)"
                >
                  {{ $t('message.task_more_2_lbl') }}
                </li>
                <li
                  v-if="task['publishContent']"
                  @click="togglePopup('ConfirmPopup', 'TARGET_UNPUBLISH', getTaskContentType(task).name)"
                >
                  {{ $t('message.task_more_4_lbl') }}
                </li>
                <li @click="togglePopup('ConfirmPopup', 'TARGET_DELETE', getTaskContentType(task).name)">
                  {{ $t('message.task_more_3_lbl') }}
                </li>
              </template>
            </ul>
          </div>
          <div class="task-info-section" @click="onClickTask">
            <h4 :title="getTaskContentType(task).name">{{ getTaskContentType(task).name }}</h4>
            <p>
              <span>
                {{ $t('message.task_last_edit_lbl') }}
                <span class="update-date">{{ task.updateDT | localDateYYYYMMDDTime }}</span>
              </span>
              <span v-if="task['publishContent']" class="publish-status published">
                {{ $t('message.task_publish_lbl') }}
              </span>
              <span v-else-if="!task['publishContent'] && task['saveContent']" class="publish-status unpublished">
                {{ $t('message.task_unpublish_lbl') }}
              </span>
              <span v-if="task['publishContent'] && task['saveContent']" class="publish-status published">
                {{ $t('message.task_update_lbl') }}
              </span>
            </p>
          </div>
        </li>
      </ul>
    </transition>
    <div class="center-page list-data-none" v-if="!tasks || tasks.length === 0">
      <loading-bar v-if="tasks === null" />
      <p v-else-if="tasks.length === 0">
        <b>등록된 타깃이미지가 없습니다.</b>
        <span>등록버튼을 클릭하여 타깃이미지를 등록 하세요.</span>
      </p>
      <p v-else-if="filteredTask.length === 0">
        {{ $t('message.no_search_result') }}
      </p>
    </div>
    <div class="toast-popup toast-popup-middle" v-if="content">
      {{ toastTitle }}
    </div>
    <div class="toast-popup toast-popup-bottom">
      <img src="/images/atoms-input-state-success-icon.svg" alt="Success Icon" />
      {{ toastTitle }}
    </div>
    <transition name="modal">
      <task-popup
        v-if="showedPopupName === 'TaskPopup'"
        :selected-eqp-id="projectId"
        :selected-task="selectedTask"
        @close="togglePopup"
      />
    </transition>
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      :i18n-named-format="i18nNamedFormat"
      @close="togglePopup"
      @confirm="onConfirmInPopup"
    />
    <copy-popup
      :is-show="showedPopupName === 'CopyPopup'"
      title="target_move_title"
      description="target_move_description"
      button="target_move_button"
      :eqp-groups="[selectedEqpByGroup.eqpGroup]"
      @close="togglePopup"
      @confirm="putMoveTask"
    />
  </div>
</template>
<script>
import $ from 'jquery';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import TaskPopup from '@/component/popup/taskPopup/TaskPopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import CopyPopup from '@/component/popup/copyPopup/CopyPopup';
import DropDownBox from '@/component/dropDown/DropDownBox';
import LoadingBar from '@/component/loadingBar/loadingBar';

export default {
  name: 'TaskList',
  props: ['projectId', 'selectedEqpByGroup'],
  data() {
    return {
      toastTitle: this.$i18n.messages[this.$lang].message.head_scene_save_popup,
      filteredTaskStatus: this.$route.query.status || 'all',
      projects: [],
      isClickOtherArea: false,
      showedPopupName: '',
      popupContentsKey: '',
      i18nNamedFormat: '',
      taskFilteringOptionNames: {
        all: this.$i18n.messages[this.$lang].message.task_all_lbl,
        publish: this.$i18n.messages[this.$lang].message.task_publish_lbl,
        unpublish: this.$i18n.messages[this.$lang].message.task_unpublish_lbl,
        update: this.$i18n.messages[this.$lang].message.task_update_lbl,
      },
    };
  },
  computed: {
    ...mapState('users', ['myInfo']),
    ...mapState('manualTasks', ['tasks', 'selectedTask']),
    ...mapState('manualProjects', ['myProjects', 'selectedProject']),
    ...mapGetters('manualProjects', ['isExistMyProject']),
    content() {
      return this.selectedTask.saveContent || this.selectedTask.publishContent;
    },
    filteredTask() {
      switch (this.filteredTaskStatus) {
        case 'all':
          return this.tasks.filter((task) => {
            return task;
          });
        case 'publish':
          return this.tasks.filter((task) => {
            if (task.publishContent) {
              return task;
            }
          });
        case 'unpublish':
          return this.tasks.filter((task) => {
            if (!task.publishContent && task.saveContent) {
              return task;
            }
          });
        case 'update':
          return this.tasks.filter((task) => {
            if (task.publishContent && task.saveContent) {
              return task;
            }
          });
        default:
          return {};
      }
    },
  },
  mounted() {},
  watch: {
    filteredTaskStatus() {
      this.addParamsToLocation();
    },
    $route(to) {
      this.filteredTaskStatus = to.query.status || 'all';
    },
  },
  methods: {
    ...mapMutations('manualTasks', ['SET_SELECTED_TASK']),
    ...mapMutations('manualProjects', ['SET_SELECTED_PROJECT']),
    ...mapActions('manualTasks', ['DELETE_TASK', 'POST_DUPLICATE_TASK_HERE', 'PUT_MOVE_TASK', 'PUT_TASK_PUBLISH']),
    onSelectedOption(selectedCycleOption) {
      this.filteredTaskStatus = selectedCycleOption;
    },
    async onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'TARGET_PUBLISH':
          await this.PUT_TASK_PUBLISH([this.selectedTask.id, true]);
          this.togglePopup();
          break;
        case 'TARGET_UPDATE':
          await this.PUT_TASK_PUBLISH([this.selectedTask.id, true]);
          this.togglePopup();
          break;
        case 'TARGET_UNPUBLISH':
          await this.PUT_TASK_PUBLISH([this.selectedTask.id, false]);
          this.togglePopup();
          break;
        case 'TARGET_DELETE':
          await this.DELETE_TASK(this.selectedTask.id);
          this.togglePopup();
          break;
        default:
          break;
      }
    },
    togglePopup(popupName, popupContentsKey, i18nNamedFormat) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
      this.i18nNamedFormat = i18nNamedFormat || '';
    },
    getTaskContentType(task) {
      if (task.saveContent) {
        return task.saveContent;
      } else {
        return task.publishContent;
      }
    },
    addParamsToLocation() {
      this.$router
        .push({
          query: {
            eqp: this.$route.query.eqp,
            project: this.$route.query.project,
            status: this.filteredTaskStatus,
          },
        })
        .catch((err) => err);
    },
    onClickCopyTask() {
      this.postDuplicateTask();
      this.onMouseLeaveTask();
    },
    async putMoveTask(eqp) {
      const selectedMoveEqp = JSON.parse(JSON.stringify(eqp));
      const taskName = this.content.name;
      await this.PUT_MOVE_TASK([this.selectedTask.id, selectedMoveEqp.manualProjectId]);
      this.togglePopup();
      this.toastTitle = `${taskName} 타깃이미지가 ${selectedMoveEqp.equipmentName} 설비로 이동되었습니다.`;
      $('.toast-popup-bottom').fadeIn(500).delay(2000).fadeOut(500);
    },
    async postDuplicateTask() {
      const taskName = this.content.name;
      await this.POST_DUPLICATE_TASK_HERE([this.selectedTask.id, this.selectedTask.manualProjectId]);
      this.toastTitle = `${taskName} ${this.$i18n.messages[this.$lang].message.task_copy_popup}`;
      const $TASKS_WRAPPER = document.getElementById('tasks-wrapper');

      if ($TASKS_WRAPPER) {
        $TASKS_WRAPPER.scrollTop = 0;
      }

      $('.toast-popup-bottom').fadeIn(500).delay(2000).fadeOut(500);
    },
    onClickMore(task) {
      this.SET_SELECTED_TASK(task);
      let moreList = document.getElementById(`task-more-list-${task.id}`);
      moreList.classList.add('active-more');
    },
    onClickTask(e) {
      if (!e.target.classList.contains('more') && !e.target.parentElement.classList.contains('more')) {
        this.onMouseLeaveTask();
      }
    },
    onMouseLeaveTask() {
      let activeList = document.getElementsByClassName('active-more')[0];
      if (activeList) {
        activeList.classList.remove('active-more');
      }
    },
    onMoveScenePage(task) {
      this.SET_SELECTED_TASK(task);
      this.$router
        .push({
          name: 'ArContentsDetail',
          params: {
            tId: task.id,
          },
          query: {
            eqp: this.$route.query.eqp,
            project: this.$route.query.project,
          },
        })
        .catch((err) => err);
    },
  },
  components: {
    LoadingBar,
    DropDownBox,
    CopyPopup,
    ConfirmPopup,
    TaskPopup,
  },
};
</script>

<style scoped lang="scss">
@import 'TaskList';
</style>
